import { createApp } from "vue";
import App from "./App.vue";
import Notifications from "@kyvg/vue3-notification";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueRecaptcha from "vue3-recaptcha-v2";
import VueGauge from "vue-gauge";

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Notifications);
app.component("vue-cookie-accept-decline", VueCookieAcceptDecline);
app.component("VueGauge", VueGauge);
app.use(VueRecaptcha, {
  siteKey: "6LdsGVQfAAAAABBvGRORbmXoCFJaV6NB2MiBz5QR",
  componentName: "vue-recaptcha", // default: 'vue-recaptcha'
  alterDomain: false, // default: false
});
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
